/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField, EnumField, DateField, BooleanField } from 'shared/uibuilder/field';
import { INVOICE_STATUSES_OPTIONS, INVOICE_STATUSES } from 'financialAnalytic/invoice/useInvoiceService';
import InvoiceHeader from 'financialAnalytic/invoice/shared/InvoiceHeader';
import RejectInvoiceButton from 'financialAnalytic/invoice/shared/button/RejectInvoiceButton';
import MarkAsPaidInvoiceButton from 'financialAnalytic/invoice/shared/button/MarkAsPaidInvoiceButton';
import CopyInvoiceButton from 'financialAnalytic/invoice/shared/button/CopyInvoiceButton';
import ApproveInvoiceButton from 'financialAnalytic/invoice/shared/button/ApproveInvoiceButton';
import CancelPaymentInvoiceButton from 'financialAnalytic/invoice/shared/button/CancelPaymentInvoiceButton';
import EditInvoiceButton from 'financialAnalytic/invoice/shared/button/EditInvoiceButton';
import SingleInvoiceBreadcrumbs from 'financialAnalytic/invoice/shared/SingleInvoiceBreadcrumbs';
import InvoiceAttachmentsListField from 'financialAnalytic/invoice/shared/field/InvoiceAttachmentsListField';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import TransfersListField from 'financialAnalytic/shared/field/TransfersListField';
import { BUSINESS_OBJECT_TYPES } from 'financialAnalytic/ledger/useLedgerService';
import { useInvoiceId } from '../InvoiceRouter';
import LinkInvoiceToPurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/LinkInvoiceToPurchaseOrderButton';
import PurchaseOrderLink from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderLink';
import {
  GOODS_AND_SERVICES_DELIVERY_STATUS_OPTIONS,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import InvoiceTotalField from '../shared/field/InvoiceTotalField';
import MarkAsDeliveredGoodsAndServicesButton from '../shared/button/MarkAsDeliveredGoodsAndServicesButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import InvoiceReferencesList from '../shared/field/InvoiceReferencesList';

const ViewInvoicePageContent = ({
  isSensitiveDataLoading = false,
  isSensitiveDataIsShown = false,
  reloadSensitiveData,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataLoading?: boolean;
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
  reloadSensitiveData?: () => void;
}) => {
  const id = useInvoiceId();
  const { setData = () => {} } = useShowContext();

  return (
    <ShowPageLayout
      entityHeader={<InvoiceHeader />}
      breadcrumbs={<SingleInvoiceBreadcrumbs />}
      headerButtons={[
        toggleSensitiveDataButton,
        <MarkAsDeliveredGoodsAndServicesButton
          isVisible={(data: any) => {
            const linkedPurchaseOrder = data.getValueBySource('linkedPurchaseOrder');

            return (
              !linkedPurchaseOrder ||
              linkedPurchaseOrder.status === PURCHASE_ORDER_STATUSES.APPROVED ||
              linkedPurchaseOrder.status === PURCHASE_ORDER_STATUSES.PARTIALLY_CLOSED
            );
          }}
          futureExpenseSource="isFutureExpense"
          invoiceIdSource="id"
          deliveryStatusSource="goodsAndServices.deliveryStatus"
          invoiceStatusSource="status"
          afterSubmit={{
            execute: (response: any) => {
              if (isSensitiveDataIsShown && reloadSensitiveData) {
                reloadSensitiveData();
              }
              setData({
                ...response,
              });
            },
          }}
        />,
        <EditInvoiceButton
          isVisible={(data: any) =>
            data.getValueBySource('isInvoiceDocumentWillArriveLater') &&
            data.getValueBySource('status') !== INVOICE_STATUSES.REJECTED
          }
        />,
        <CopyInvoiceButton />,
        <LinkInvoiceToPurchaseOrderButton
          statusSource="status"
          officeSource="office"
          isVisible={(data: any) => !data.getValueBySource('linkedPurchaseOrder')?.id}
        />,
        <ApproveInvoiceButton statusSource="status" />,
        <MarkAsPaidInvoiceButton isSensitiveDataIsShown={isSensitiveDataIsShown} statusSource="status" />,
        <CancelPaymentInvoiceButton
          statusSource="status"
          isVisible={(data: any) =>
            data.getValueBySource('linkedPurchaseOrder')?.status !== PURCHASE_ORDER_STATUSES.CLOSED
          }
        />,
        <RejectInvoiceButton
          statusSource="status"
          isVisible={(data: any) =>
            data.getValueBySource('linkedPurchaseOrder')?.status !== PURCHASE_ORDER_STATUSES.CLOSED
          }
        />,
      ]}
      hasSeparateSections
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField label="Invoice Number" source="invoiceNumber" />
          <DateField label="Date" source="invoiceDate" />
          <OfficeField source="office" label="Office" />
        </SectionRow>
        <SectionRow>
          <TextField label="Name" source="name" />
          <EnumField label="Status" source="status" options={INVOICE_STATUSES_OPTIONS} />
          <TextField
            label="Reject reason"
            source="rejectReason"
            isVisible={(data: any) => data.getValueBySource('status') === INVOICE_STATUSES.REJECTED}
          />
          <BooleanField source="isPurchaseClassifiedAsAsset" label="Is purchase classified as asset?" />
        </SectionRow>
        <SectionRow>
          <InvoiceTotalField
            label="Total"
            source="subTotal.amount"
            currencySource="subTotal.currency"
            isVisible={isSensitiveDataIsShown}
          />
          <InvoiceTotalField
            label="Paid amount"
            source="invoicePaidSubTotal.amount"
            currencySource="invoicePaidSubTotal.currency"
            isVisible={isSensitiveDataIsShown}
          />
          <BooleanField source="isFutureExpense" label="Is future expense?" />
          <LedgerLink source="account" nameSource="account" label="Account" />
          <PurchaseOrderLink
            source="linkedPurchaseOrder.id"
            nameSource="linkedPurchaseOrder.title"
            label="Purchase Order"
          />
        </SectionRow>
        <BigSectionRow>
          <TextField label="Description" source="description" />
        </BigSectionRow>
        <SectionRow>
          <InvoiceAttachmentsListField source="invoiceDocumentId" label="Document" isOneArtifact />
        </SectionRow>
      </ShowSection>
      <ShowSection title="Requisites">
        <SectionRow>
          <TextField source="requisites.bankName" label="Bank Name" />
          <TextField source="requisites.bankAccount" label="Bank Account" />
          <TextField source="requisites.recipient" label="Recipient" />
        </SectionRow>
      </ShowSection>
      <ShowSection title="Goods and services">
        <SectionRow>
          <EnumField
            label="Delivery Status"
            source="goodsAndServices.deliveryStatus"
            options={GOODS_AND_SERVICES_DELIVERY_STATUS_OPTIONS}
          />
          <DateField label="Delivery Date" source="goodsAndServices.deliveryDate" />
          <InvoiceTotalField
            label="Delivered amount"
            source="invoiceDeliveredSubTotal.amount"
            currencySource="invoiceDeliveredSubTotal.currency"
            width="25%"
            isVisible={isSensitiveDataIsShown || isSensitiveDataLoading}
          />
        </SectionRow>
      </ShowSection>
      <ShowSection isVisible={isSensitiveDataIsShown || isSensitiveDataLoading}>
        <TransfersListField
          businessObjectId={id}
          businessObjectType={BUSINESS_OBJECT_TYPES.INVOICE}
          startDateSource="invoiceDate"
          endDateSource="invoicePaidDate"
        />
      </ShowSection>
      <ShowSection title="References">
        <InvoiceReferencesList source="references" />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewInvoicePageContent;
