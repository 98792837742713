import FormSection from 'shared/layout/form/FormSection';
import React, { useEffect, useMemo, useState } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useBudgetingService from 'erp/serviceCatalog/budgets/budgetingService';
import useMessageService, { WarningMessage } from 'shared/message/messageService';
import { DateInput } from 'shared/uibuilder/form/input';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import BudgetDetailsSection from '../shared/inputs/BudgetDetailsSection';
import useDateService from 'shared/uibuilder/dateService/useDateService';

const ServiceBudgetForm = () => {
  const { data, setFormData } = useFormContext();
  const budgetId = useServiceCatalogId();
  const { getActiveStructure } = useBudgetingService();
  const { addMessage, cleanMessage } = useMessageService();
  const { getToday } = useDateService();
  const today = getToday();

  const [structureLoading, setStructureLoading] = useState(false);

  const startDate = useMemo(() => data?.startDate, [data?.startDate]);
  const endDate = useMemo(() => data?.endDate, [data?.endDate]);

  useEffect(() => {
    const fetchStructure = async () => {
      setStructureLoading(true);
      const activeStructure = await getActiveStructure(budgetId, data?.startDate);
      if (activeStructure.error) {
        addMessage(new WarningMessage("There's no budget structure for that start date."));
        setStructureLoading(false);
        return;
      }
      cleanMessage();

      const { categories, objectives, ...oldData } = data as any;
      setFormData?.({
        budgetId,
        categories: activeStructure.categories.map((category: any) => {
          return {
            name: category,
            amount: '',
          };
        }),
        objectives: activeStructure.objectives,
        ...oldData,
      });

      setStructureLoading(false);
    };

    if (startDate) {
      fetchStructure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  return (
    <>
      <FormSection title="Budget Information">
        <FormRow>
          <DateInput
            minDate={today}
            maxDate={endDate}
            source="startDate"
            dateFormat={DATE_FORMAT.API_ONLY_MONTH}
            isRequired
          />
          <DateInput minDate={startDate} source="endDate" dateFormat={DATE_FORMAT.API_ONLY_MONTH} isRequired />
        </FormRow>
      </FormSection>
      <LoadingDecorator loading={structureLoading} deps={[structureLoading]}>
        <FormSection>
          <BudgetDetailsSection />
        </FormSection>
      </LoadingDecorator>
    </>
  );
};

export default ServiceBudgetForm;
