import React from 'react';
import moment, { Moment } from 'moment';
import classnames from 'classnames';
import { useScsApi } from 'api';
import { nanoid } from 'nanoid';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import PerformanceHeatmapCell from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapCell';
import Box from 'uibuilder/Box';

export enum DAY_TYPES {
  WORKING = 'WORKING',
  HOLIDAY = 'HOLIDAY',
  LEAVE = 'LEAVE',
}

export enum DAY_MARK {
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export const DIVIDER = 'DIVIDER';

export enum METRICS {
  TIMELY_CHECKIN = 'TIMELY_CHECKIN',
  SAME_DAY_TIME_REPORT = 'SAME_DAY_TIME_REPORT',
  LOCATION_TIMEZONE = 'LOCATION_TIMEZONE',
  MICRO_AVAILABILITY = 'MICRO_AVAILABILITY',
  EFFECTIVE_CAPACITY = 'EFFECTIVE_CAPACITY',
  TICKETS_PLANNED = 'TICKETS_PLANNED',
  RESOLVED_TICKETS = 'RESOLVED_TICKETS',
  BLOCKED_TICKETS = 'BLOCKED_TICKETS',
  REASSIGNED_TICKETS = 'REASSIGNED_TICKETS',
  REJECTED_TICKETS = 'REJECTED_TICKETS',
  ASSIGNED_TICKETS = 'ASSIGNED_TICKETS',
  OVERDUE_PROCESSES_TICKETS = 'OVERDUE_PROCESSES_TICKETS',
}

export const DAY_WORKING_NORM = 8;

type PerformanceItemData = {
  sum: number;
  daysStatistic: Dictionary<number>;
  marksStatistic: Dictionary<number>;
  end: Moment;
  start: Moment;
};

const getCapacityCellTooltip = ({ daysStatistic, sum }: PerformanceItemData) => (
  <div className="performance-heatmap__cell-tooltip">
    <p>
      <span className="performance-heatmap__cell-tooltip-label">Working norm:</span>{' '}
      {daysStatistic.WORKING * DAY_WORKING_NORM}
    </p>
    <p className="mb-1">
      <span className="performance-heatmap__cell-tooltip-label">Spent time:</span> {+(sum?.toFixed(2) ?? 0)}
    </p>
    <p>
      <span className="performance-heatmap__cell-tooltip-label">Working days:</span> {daysStatistic.WORKING}
    </p>
    <p>
      <span className="performance-heatmap__cell-tooltip-label">Weekends and public holidays:</span>{' '}
      {daysStatistic.HOLIDAY}
    </p>
    <p>
      <span className="performance-heatmap__cell-tooltip-label">Leaves:</span> {daysStatistic.LEAVE}
    </p>
  </div>
);

const getAvailabilityMonthlyValue = ({ sum, start, end, marksStatistic }: PerformanceItemData) => {
  const marks = [DAY_MARK.POSITIVE, DAY_MARK.NEUTRAL, DAY_MARK.NEGATIVE];
  const today = moment();
  const isActiveWeek = !end || (start?.isBefore(today) && today.isBefore(end)) || end.isBefore(today);

  return (
    <Box
      component="ul"
      sx={{
        gridTemplateColumns: `repeat(${marks.length}, 1fr)`,
      }}
      className="performance-heatmap__days"
    >
      {marks.map(mark => (
        <PerformanceHeatmapCell
          key={nanoid()}
          dayType={DAY_TYPES.WORKING}
          metric={{
            value: marksStatistic[mark],
            mark,
          }}
          className={classnames({
            'performance-heatmap__day--hidden': !+marksStatistic[mark],
            'performance-heatmap__day--empty': !isActiveWeek,
          })}
        />
      ))}
    </Box>
  );
};

const getMetricLegendInTooltip = (data: Array<Dictionary<string>>) => (
  <div className="performance-heatmap__legend performance-heatmap__legend--tooltip">
    {data.map(({ mark, message }) => (
      <div key={nanoid()} className="performance-heatmap__legend-item">
        <div>
          <div className="performance-heatmap__legend-cell" data-datetype={DAY_TYPES.WORKING} data-state={mark} />
        </div>
        <span>{message}</span>
      </div>
    ))}
  </div>
);

export const METRICS_CONFIG: any = {
  [METRICS.TIMELY_CHECKIN]: {
    label: 'Timely check in',
    tooltip: getMetricLegendInTooltip([
      {
        mark: DAY_MARK.POSITIVE,
        message: 'Daily check in was confirmed before 12.00PM',
      },
      {
        mark: DAY_MARK.NEUTRAL,
        message: 'Daily check in was confirmed after 12.00PM',
      },
      {
        mark: DAY_MARK.NEGATIVE,
        message: 'Daily check in was skipped or not completed at all',
      },
    ]),
    colored: true,
    monthly: {
      getValue: getAvailabilityMonthlyValue,
    },
  },
  [METRICS.SAME_DAY_TIME_REPORT]: {
    label: 'Same day time report',
    tooltip: getMetricLegendInTooltip([
      {
        mark: DAY_MARK.POSITIVE,
        message: 'Time reports for day were tracked on the same working day',
      },
      {
        mark: DAY_MARK.NEGATIVE,
        message: 'Time reports for day were tracked the next day or later',
      },
    ]),
    colored: true,
    monthly: {
      getValue: getAvailabilityMonthlyValue,
    },
  },
  [METRICS.LOCATION_TIMEZONE]: {
    label: 'Location/time zone',
    tooltip: 'The employee worked from the planned location and time zone',
    colored: true,
    monthly: {
      getValue: getAvailabilityMonthlyValue,
    },
  },
  [METRICS.MICRO_AVAILABILITY]: {
    label: 'Micro availability',
    tooltip: 'The employee have been available in Teams as planned',
    colored: true,
    monthly: {
      getCellTooltip: getCapacityCellTooltip,
    },
  },
  [METRICS.EFFECTIVE_CAPACITY]: {
    label: 'Effective capacity',
    tooltip: getMetricLegendInTooltip([
      {
        mark: DAY_MARK.POSITIVE,
        message: 'The employee worked 8.00 or more business hours',
      },
      {
        mark: DAY_MARK.NEUTRAL,
        message: 'The employee worked 7.50-7.99 business hours',
      },
      {
        mark: DAY_MARK.NEGATIVE,
        message: 'The employee worked less than 7.49 business hours',
      },
    ]),
    colored: true,
    monthly: {
      getCellTooltip: getCapacityCellTooltip,
    },
  },
  [METRICS.TICKETS_PLANNED]: {
    label: 'Tickets planned for today',
    tooltip: 'Number of tickets planned for today before check in confirmation',
    colored: false,
  },
  [METRICS.RESOLVED_TICKETS]: {
    label: 'Resolved tickets',
    tooltip: 'Number of tickets in "Resolved", "Closed" statuses',
    colored: false,
  },
  [METRICS.BLOCKED_TICKETS]: {
    label: 'Blocked tickets',
    tooltip: 'Number of tickets moved to "Blocked" backlog category',
    colored: false,
  },
  [METRICS.REASSIGNED_TICKETS]: {
    label: 'Reassigned tickets',
    tooltip: 'Number of tickets reassigned to other employee',
    colored: false,
  },
  [METRICS.REJECTED_TICKETS]: {
    label: 'Rejected tickets',
    tooltip: 'Number of tickets in "Rejected" status',
    colored: false,
  },
  [METRICS.ASSIGNED_TICKETS]: {
    label: 'Daily assigned tickets',
    tooltip: 'Number of tickets assigned to the employee',
    colored: false,
  },
  [METRICS.OVERDUE_PROCESSES_TICKETS]: {
    label: 'Overdue processes tickets',
    tooltip: 'Number of resolved processes tickets with expired SLA',
    colored: false,
  },
};

export type MetricProps = {
  metric?: {
    message?: React.ReactNode;
    value?: React.ReactNode;
    mark?: Nullable<DAY_MARK>;
    date?: string;
  };
  day?: string;
  dayType?: DAY_TYPES;
};

export type MetricsProps = Dictionary<MetricProps[]>;

export const SATURDAY = 6;
export const SUNDAY = 0;
export const DAYS_IN_WEEK = 7;

export const isWeekend = (day?: Nullable<number>): boolean =>
  typeof day === 'number' && (day === SUNDAY || day === SATURDAY);

export const READ_PERFORMANCE_REPORT = 'READ_PERFORMANCE_REPORT';
const PERFORMANCE_REPORT_URL = '/metrics';

const usePerformanceReportService = () => {
  const { sendGetRequest } = useScsApi();
  const { data } = useShowContext();

  const search = async ({ filter = {} }: any) => {
    try {
      const url = `/users/${data?.getValueBySource('workingEmail')}${PERFORMANCE_REPORT_URL}/${filter.period?.eq}`;
      const response = await sendGetRequest(url);
      const result = await response.json();
      return { result };
    } catch (e) {
      return { result: {} };
    }
  };

  return {
    search,
  };
};

export default usePerformanceReportService;
