import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import {
  TimelineType
} from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import React from 'react';
import TimelineRecordHeader
  from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordHeader';
import ServiceHealthField from 'erp/serviceCatalog/shared/field/ServiceHealthField';

const ServiceHealthHistoryRecordLayout = ({ ...props }: any) => {
  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      buttons={[]}
      {...props}
      timelineHeader={(rest: any) => <TimelineRecordHeader createdAtSource="registeredAt" />}
      fields={
        <Collapsible>
          <ServiceHealthField label="Issues" />
        </Collapsible>
      }
    />);
};

export default ServiceHealthHistoryRecordLayout;
