import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { useAccountUrl } from 'crm/account/index';
import { useContactUrl } from 'crm/contact/ContactRouter';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';
import { useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import { ResourceId } from 'shared/crud/baseCrudService';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const useCustomHtmlTagHelper = () => {
  const { getSingleEntityUrl: getEmployeeUrl } = useEmployeeUrl();
  const { getSingleEntityUrl: getCandidateUrl } = useCandidateUrl();
  const { getSingleEntityUrl: getLeadUrl } = useLeadUrl();
  const { getTimelineUrl: getOpportunityUrl } = useOpportunityUrl();
  const { getSingleEntityUrl: getAccountUrl } = useAccountUrl();
  const { getSingleEntityUrl: getContactUrl } = useContactUrl();
  const { getSingleEntityUrl: getEmployeeOfferUrl } = useEmployeeOfferUrl();
  const { getSingleEntityUrl: getCandidateOfferUrl } = useCandidateOfferUrl();
  const { getSingleEntityUrl: getProjectUrl } = useProjectUrl();
  const { getSingleEntityUrl: getCompanyServiceUrl } = useServiceUrl();

  const replaceTag = (
    text: string,
    tag: string | ((id: ResourceId) => string) | ((id?: string) => string),
    urlBuilder: ((id: StringOrNumber) => string) | ((id?: string | undefined) => string),
  ) => {
    const regexp = new RegExp(`<${tag}\\sid=['|"]([\\S\\d]+?)['|"]((>(.*?)</${tag}>)|(/>))`, 'mg');
    let result = text;
    let matched;

    // eslint-disable-next-line no-cond-assign
    while ((matched = regexp.exec(text))) {
      const fullTag = matched[0];
      const id = matched[1];
      const value = matched[4];

      const newTag = `<a href="${urlBuilder(id)}" target="_blank">${value || id}</a>`;

      result = result.replace(fullTag, newTag);
    }

    return result;
  };

  const mapTags = (description: any) => {
    const tagsToMap = [
      ['employee', getEmployeeUrl],
      ['candidate', getCandidateUrl],
      ['lead', getLeadUrl],
      ['opportunity', getOpportunityUrl],
      ['contact', getContactUrl],
      ['account', getAccountUrl],
      ['employeeoffer', getEmployeeOfferUrl],
      ['candidateoffer', getCandidateOfferUrl],
      ['project', getProjectUrl],
      ['service', getCompanyServiceUrl],
    ];

    let result = description;

    tagsToMap.forEach(([tag, urlFunction]) => {
      result = replaceTag(
        result,
        tag,
        urlFunction as ((id: StringOrNumber) => string) | ((id?: string | undefined) => string),
      ); // Spread into two arguments
    });

    return result;
  };

  return {
    mapTags,
  };
};

export default useCustomHtmlTagHelper;
