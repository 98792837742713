import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import useServiceHealthService from 'erp/serviceCatalog/health/useServiceHealthService';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import ViewServiceHealthHistoryBreadcrumbs
  from 'erp/serviceCatalog/health/Show/ViewServiceHealthHistoryBreadcrumbs';
import ServiceBudgetEntityHeader from 'erp/serviceCatalog/budgets/shared/ServiceBudgetEntityHeader';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import useServiceHealthHistoryRegistry
  from 'erp/serviceCatalog/health/serviceHealthHistoryRegistry';

const ViewServiceHealthHistory = () => {
  const id = useServiceCatalogId();
  const { getById } = useServiceCatalogService();
  const { searchHistory } = useServiceHealthService();

  const registry = useServiceHealthHistoryRegistry();

  return (
    <Show getDataMethod={() => getById(id)}>
      <Timeline getDataMethod={async req => searchHistory(id, req)} registries={[registry as any]}>
        <TimelinePageLayout
          title="Health History"
          breadcrumbs={<ViewServiceHealthHistoryBreadcrumbs />}
          entityHeader={<ServiceBudgetEntityHeader />}
          menu={<ViewServiceCatalogPageMenu />}
        >
          <TimelineLayout noEntriesMessage="No entries in this health history yet."/>
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default ViewServiceHealthHistory;
