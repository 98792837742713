import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import SummaryMenuItem from 'uibuilder/menuitem/SummaryMenuItem';
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthorization from 'shared/authorization/authorizationService';
import { KPI_STRUCTURE_READ } from '../kpi/useKPIService';

const ViewServiceCatalogPageMenu = () => {
  const id = useServiceCatalogId();
  const { isFeatureEnabled } = useFeatureToggle();
  const { isGranted } = useAuthorization();

  const { getSingleEntityUrl, getBudgetsUrl, getBudgetStructuresUrl, getHealthHistoryUrl, getKPIStructuresUrl } =
    useServiceCatalogUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem exact linkUrl={getSingleEntityUrl(id)} />
      {isFeatureEnabled(Features.BUDGETING) ? (
        <>
          <SidebarMenuItem exact icon="money" linkUrl={getBudgetsUrl(id)} name="Budgets" />
          <SidebarMenuItem exact icon="list" linkUrl={getBudgetStructuresUrl(id)} name="Budgets Structure" />
          {isFeatureEnabled(Features.SERVICE_HEALTH) ? (
            <SidebarMenuItem exact icon="heart" linkUrl={getHealthHistoryUrl(id)} name="Health History" />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {isGranted(KPI_STRUCTURE_READ) ? (
        <SidebarMenuItem exact icon="check" linkUrl={getKPIStructuresUrl(id)} name="KPI Structure" />
      ) : (
        <></>
      )}
    </SidebarStickyMenu>
  );
};

export default ViewServiceCatalogPageMenu;
