import useCrudService from 'shared/crud';
import { useServiceManagementApi } from 'api';
import { SERVICE_CATALOG_HEALTH_STATUSES } from 'erp/serviceCatalog/useServiceCatalogService';

export const RESOURCE_URL = '/services/health';

export const SERVICE_HEALTH_ISSUES = {
  NO_SERVICE_OWNER: 'NO_SERVICE_OWNER',
  NO_BUDGET_STRUCTURE: 'NO_BUDGET_STRUCTURE',
  NO_KPI_STRUCTURE: 'NO_KPI_STRUCTURE',
  NO_ACTIVE_BUDGET: 'NO_ACTIVE_BUDGET',
  NO_BUDGET_FOR_YEAR_AHEAD: 'NO_BUDGET_FOR_YEAR_AHEAD',
};

export const SERVICE_HEALTH_ISSUES_OPTIONS = {
  [SERVICE_HEALTH_ISSUES.NO_SERVICE_OWNER]: 'No service owner',
  [SERVICE_HEALTH_ISSUES.NO_BUDGET_STRUCTURE]: 'No budget structure',
  [SERVICE_HEALTH_ISSUES.NO_KPI_STRUCTURE]: 'No KPI structure',
  [SERVICE_HEALTH_ISSUES.NO_ACTIVE_BUDGET]: 'No active budget',
  [SERVICE_HEALTH_ISSUES.NO_BUDGET_FOR_YEAR_AHEAD]: 'No budget for year ahead',
};

export const SERVICE_HEALTH_ISSUES_IMPORTANCE = {
  [SERVICE_HEALTH_ISSUES.NO_SERVICE_OWNER]: SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL,
  [SERVICE_HEALTH_ISSUES.NO_BUDGET_STRUCTURE]: SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL,
  [SERVICE_HEALTH_ISSUES.NO_KPI_STRUCTURE]: SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL,
  [SERVICE_HEALTH_ISSUES.NO_ACTIVE_BUDGET]: SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL,
  [SERVICE_HEALTH_ISSUES.NO_BUDGET_FOR_YEAR_AHEAD]: SERVICE_CATALOG_HEALTH_STATUSES.WARNING,
};

const useServiceHealthService = () => {
  const {
    search: baseSearch
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useServiceManagementApi,
  });

  const searchHistory = (id: string, request: any) => {
    const params = {
      filterGroup: {
        condition: 'AND',
        filterType: 'FILTER_GROUP',
        filters: [
          {
            filterType: 'FILTER',
            property: 'service.id',
            operator: 'EQ',
            value: id,
            joinType: 'LEFT',
          },
        ],
      },
      sort: {
        registeredAt: 'DESC',
      },
      type: 'advanced',
    };

    return baseSearch({
      ...request,
      ...params,
    });
  };

  return {
    searchHistory
  };
};

export default useServiceHealthService;
