import { READ_SERVICE_CATALOG } from 'erp/serviceCatalog/useServiceCatalogService';
import ServiceHealthHistoryRecordLayout
  from 'erp/serviceCatalog/health/ServiceHealthHistoryRecordLayout';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';

const useServiceHealthHistoryRegistry = () => ({
  RecordLayout: ServiceHealthHistoryRecordLayout,
  isApplicable: (item: TimelineItem) => item,
  withoutAddFunctionality: true,
  source: '',
  urlPostfix: '/health',
  hasFullData: true,
  permissionToCheck: READ_SERVICE_CATALOG,
});

export default useServiceHealthHistoryRegistry;
