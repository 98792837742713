import React from 'react';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import SingleServiceCatalogBreadcrumbs
  from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';

const ViewServiceHealthHistoryBreadcrumbs = ({ children } : { children? : React.ReactNode }) => {
  const id = useServiceCatalogId();
  const { getHealthHistoryUrl } = useServiceCatalogUrl();

  return (
    <SingleServiceCatalogBreadcrumbs>
      <SingleBreadcrumbItem entity='Health History' link={getHealthHistoryUrl(id)} />
      {children}
    </SingleServiceCatalogBreadcrumbs>
  );
};

export default ViewServiceHealthHistoryBreadcrumbs;
