import useBudgetVersionService from 'erp/serviceCatalog/budgets/budgetVersionService';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import React from 'react';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import StatusBadgeField from 'erp/serviceCatalog/budgets/shared/field/StatusBadgeField';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { useParams } from 'react-router-dom';
import ViewServiceBudgetBreadcrumbs
  from 'erp/serviceCatalog/budgets/Show/ViewServiceBudgetBreadcrumbs';
import CategoriesAndObjectives from 'erp/serviceCatalog/budgets/Show/CategoriesAndObjectives';
import ServiceBudgetEntityHeader from 'erp/serviceCatalog/budgets/shared/ServiceBudgetEntityHeader';
import ApproveBudgetButton from 'erp/serviceCatalog/budgets/shared/button/ApproveBudgetButton';
import RejectBudgetButton from 'erp/serviceCatalog/budgets/shared/button/RejectBudgetButton';
import TextField from 'shared/uibuilder/field/TextField';


const ViewServiceBudget = () => {
  const { budgetId } = useParams<Dictionary<string>>();
  const { getById } = useBudgetVersionService();

  return (
    <Show getDataMethod={() => getById(budgetId!)}>
      <ShowPageLayout
        breadcrumbs={<ViewServiceBudgetBreadcrumbs />}
        entityHeader={<ServiceBudgetEntityHeader />}
        headerButtons={[
          <ApproveBudgetButton />,
          <RejectBudgetButton />,
        ]}
      >
        <ShowSection title="Budget" actions={[<StatusBadgeField />]}>
          <SectionRow>
            <DateField label="Start Date" source="startDate" dateFormat={DATE_FORMAT.MONTH} />
            <DateField label="End Date" source="endDate" dateFormat={DATE_FORMAT.MONTH} />
            <TextField label="Rejection Reason" source="rejectionReason" />
          </SectionRow>
          <br />
        </ShowSection>
        <CategoriesAndObjectives />
      </ShowPageLayout>
    </Show>
  );
};

export default ViewServiceBudget;
