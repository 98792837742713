import { BaseFieldLayoutType, TextField } from 'shared/uibuilder/field';
import { SERVICE_HEALTH_ISSUES_OPTIONS } from 'erp/serviceCatalog/health/useServiceHealthService';
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';

type ServiceHealthFieldProps = {
  label: string;
  source?: string;
};

const ServiceHealthField = ({ label, source = "" } : ServiceHealthFieldProps) => {
  const { data } = useShowContext();
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const getPrefix = () => {
    if (source === '') {
      return source;
    } else {
      return `${source}.`
    }
  };

  const issues = data.getValueBySource(`${getPrefix()}issues`);
  const renderIssues = () => {
    if (issues.length === 0) {
      return EMPTY_VALUE_INDICATOR;
    }

    return <ul>
      {issues.map((issue: any) => <li>{SERVICE_HEALTH_ISSUES_OPTIONS[issue.type]}</li>)}
    </ul>;
  };

  return (
    <>
      <TextField label="Status" source={`${getPrefix()}status`} />
      <BaseFieldLayout label={label} value={renderIssues()} />
    </>
  );
};

export default ServiceHealthField;
