import useBudgetingService from 'erp/serviceCatalog/budgets/budgetingService';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import React from 'react';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import ServiceBudgetStructureForm from 'erp/serviceCatalog/budgets/structure/Create/ServiceBudgetStructureForm';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';

const CreateServiceBudgetStructure = ({ onCancel, afterSubmit }: any) => {
  const id = useServiceCatalogId();
  const { createStructure, getValidationSchema } = useBudgetingService();

  return (
    <CreateForm
      submitFormFunc={async req => createStructure(id, req)}
      afterSubmit={afterSubmit}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ServiceBudgetStructureForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateServiceBudgetStructure;
