/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { TextField, DateField } from 'shared/uibuilder/field';
import ServiceCatalogHeader from 'erp/serviceCatalog/shared/ServiceCatalogHeader';
import ArchiveServiceCatalogButton from 'erp/serviceCatalog/shared/button/ArchiveServiceCatalogButton';
import AssignServiceCatalogOwnerButton from 'erp/serviceCatalog/shared/button/AssignServiceCatalogOwnerButton';
import SingleServiceCatalogBreadcrumbs from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import { EmployeeLinkFieldWithNameLoading } from 'erp/employee';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import ServiceHealthField from 'erp/serviceCatalog/shared/field/ServiceHealthField';
import useFeatureToggle, { Features } from 'featuretoggle';

const ViewServiceCatalogPageContent = () => {
  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <ShowPageLayout
      entityHeader={<ServiceCatalogHeader />}
      breadcrumbs={<SingleServiceCatalogBreadcrumbs />}
      headerButtons={[
        <AssignServiceCatalogOwnerButton statusSource="status" />,
        <ArchiveServiceCatalogButton statusSource="status" />,
      ]}
      menu={<ViewServiceCatalogPageMenu />}
    >
      <ShowSection title="Service information">
        <SectionRow>
          <TextField label="Name" source="name" />
          <EmployeeLinkFieldWithNameLoading source="owner" label="Owner" />
          <DateField source="updatedAt" label="Last Modified Date" width="20%" isSortable />
        </SectionRow>
      </ShowSection>
      {isFeatureEnabled(Features.SERVICE_HEALTH)
        ? <ShowSection title="Current Service Health">
          <ServiceHealthField label="Issues" source="currentHealth" />
        </ShowSection>
        : <></>}
    </ShowPageLayout>
  );
};

export default ViewServiceCatalogPageContent;
