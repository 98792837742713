import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import useMessageService, { ErrorMessage, SuccessMessage } from 'shared/message/messageService';
import { AfterSubmitProps } from '../formHelper';
import { HTTP } from 'shared/api/const';
import { flushSync } from 'react-dom';

export interface FormsData {
  [key: string]: any;
}

export interface FormListData {
  customId: string;
  data: FormsData;
}

interface FormListHelperProps {
  isBatchUpload: boolean;
  isBatchUploadBySize: boolean;
  batchUploadSize?: number;
  initialListData: FormData[];
  submitFormFunc: (data: any[] | any) => Promise<object | void>;
  afterSubmit?: AfterSubmitProps<(response: any) => any, string | ((response: any) => any)>;
}

const useFormList = ({
  isBatchUploadBySize,
  batchUploadSize = 10,
  isBatchUpload,
  initialListData = [],
  submitFormFunc,
  afterSubmit,
}: FormListHelperProps) => {
  const { addMessageAndRedirect, addMessage } = useMessageService();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errorsAfterSubmit, setErrorsAfterSubmit] = useState<any[]>([]);
  const [formList, setFormList] = useState<FormListData[]>(
    initialListData.map(item => ({ customId: nanoid(), data: item })),
  );
  const [showError, setShowError] = useState<boolean>(false);
  const formRefs = useRef<Map<string, any>>(new Map());
  const [formState, setFormState] = useState<{ formsData: FormListData[]; errorsForms: any[] }>({
    formsData: [],
    errorsForms: [],
  });

  const hasErrors = useMemo(
    () => formState.errorsForms.some(({ errors }) => Object.values(errors).some((value: any) => value?.length > 0)),
    [formState.errorsForms],
  );

  const updateFormState = useCallback((id: string, data: any, type: 'data' | 'errors') => {
    setFormState(prevState => {
      const formIndex = prevState[type === 'data' ? 'formsData' : 'errorsForms'].findIndex(
        form => form.customId === id,
      );
      const updatedForms = type === 'data' ? [...prevState.formsData] : [...prevState.errorsForms];

      if (formIndex !== -1) {
        updatedForms[formIndex] = { ...updatedForms[formIndex], ...data };
      } else {
        updatedForms.push({ customId: id, ...data });
      }

      return { ...prevState, [type === 'data' ? 'formsData' : 'errorsForms']: updatedForms };
    });
  }, []);

  const clearFormError = (id: string) => {
    setErrorsAfterSubmit(prevErrorList => prevErrorList.filter(form => form.customId !== id));
  };

  const removeForm = useCallback((id: string) => {
    formRefs.current.delete(id);
    setFormList(prevFormList => prevFormList.filter(form => form.customId !== id));
    setErrorsAfterSubmit(prevErrorList => prevErrorList.filter(form => form.customId !== id));
    setFormState(prevState => ({
      formsData: prevState.formsData.filter(form => form.customId !== id),
      errorsForms: prevState.errorsForms.filter(form => form.customId !== id),
    }));
  }, []);

  const setFormContext = useCallback((id: string, context: any) => {
    formRefs.current.set(id, context);
  }, []);

  const validateAllForms = useCallback(() => {
    let isValid = true;
    formRefs.current.forEach(context => {
      const errors = context.validateForm();
      if (Object.keys(errors).length > 0) {
        isValid = false;
      }
    });
    return isValid;
  }, []);

  const submitForms = useCallback(async () => {
    const { formsData } = formState;

    const handleBatchAllSubmit = async (data: FormsData[]) => {
      try {
        const response = await submitFormFunc(data);
        flushSync(() => setFormList([]));

        if (afterSubmit) {
          const { message, redirect } = afterSubmit;
          let redirectUrl = redirect;
          if (typeof redirect === 'function') {
            redirectUrl = redirect(response);
          }
          if (redirectUrl && message) {
            addMessageAndRedirect(new SuccessMessage(message), redirectUrl as string);
          } else if (message) {
            addMessage(new SuccessMessage(message));
          }
        }
      } catch (submitErrors) {
        if (submitErrors instanceof Error) {
          addMessage(new ErrorMessage(submitErrors.message));
        } else {
          addMessage(new ErrorMessage('Something went wrong.'));
        }
      }
    };

    const handleResponseError = async (submitErrors: any, customId: string) => {
      const responseError = submitErrors as any;
      if (responseError.status === HTTP.BAD_REQUEST) {
        const error = await responseError.json();
        return { customId, error: { message: error.message || 'Something went wrong.' } };
      }
      return { customId, error: { message: 'Something went wrong.' } };
    };

    const processChunk = async (chunk: FormListData[]) => {
      return Promise.all(
        chunk.map(async ({ data, customId }: any) => {
          try {
            const response = await submitFormFunc(data);
            flushSync(() => removeForm(customId));
            return response;
          } catch (submitErrors) {
            return handleResponseError(submitErrors, customId);
          }
        }),
      );
    };

    const processBatchChunk = async (chunk: FormListData[]) => {
      try {
        const response = await submitFormFunc(chunk.map(item => item.data));
        flushSync(() => chunk.forEach(({ customId }) => removeForm(customId)));
        return Array.isArray(response) ? response : [response];
      } catch (submitErrors) {
        return Promise.all(chunk.map(({ customId }) => handleResponseError(submitErrors, customId)));
      }
    };

    const processChunksSequentially = async (chunks: FormListData[][], responses: any[], process: any) => {
      return chunks.reduce(async (prevPromise, chunk) => {
        await prevPromise;
        const chunkResponses = await process(chunk);
        responses.push(...chunkResponses);
      }, Promise.resolve());
    };

    const chunkArray = <T>(array: T[], size: number): T[][] => {
      return array.reduce((acc, _, index) => {
        if (index % size === 0) acc.push(array.slice(index, index + size));
        return acc;
      }, [] as T[][]);
    };

    if (isBatchUpload) {
      // Submit all from by 1 request
      const data = formsData.map(item => item.data);
      handleBatchAllSubmit(data);
    } else if (batchUploadSize && isBatchUploadBySize) {
      // Submit by size (1 request = batchUploadSize forms)
      try {
        const responses: any[] = [];

        const formDataChunks = chunkArray(formsData, batchUploadSize);
        await processChunksSequentially(formDataChunks, responses, processBatchChunk);

        const responseErrors = responses.filter((response: any) => response?.error);

        if (responseErrors.length === 0) {
          if (afterSubmit) {
            const { message, redirect } = afterSubmit;
            if (redirect && message) {
              addMessageAndRedirect(new SuccessMessage(message), redirect as string);
            } else if (message) {
              addMessage(new SuccessMessage(message));
            }
          }
        } else {
          setErrorsAfterSubmit(responseErrors);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred during form submission:', error);
      }
    } else {
      // Submit by 3 requests (1 form = 1 request)
      const batchSize = 3;
      try {
        const responses: any[] = [];

        const formDataChunks = chunkArray(formsData, batchSize);
        await processChunksSequentially(formDataChunks, responses, processChunk);

        const responseErrors = responses.filter((response: any) => response?.error);

        if (responseErrors.length === 0) {
          if (afterSubmit) {
            const { message, redirect } = afterSubmit;
            if (redirect && message) {
              addMessageAndRedirect(new SuccessMessage(message), redirect as string);
            }
          }
        } else {
          setErrorsAfterSubmit(responseErrors);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred during form submission:', error);
      }
    }
  }, [
    formState,
    isBatchUpload,
    batchUploadSize,
    isBatchUploadBySize,
    submitFormFunc,
    afterSubmit,
    addMessageAndRedirect,
    addMessage,
    removeForm,
  ]);

  const handleSubmit = useCallback(async () => {
    setShowError(true);
    if (validateAllForms()) {
      setIsLoading(true);
      await submitForms();
      setIsLoading(false);
    }
  }, [validateAllForms, submitForms]);

  useEffect(() => {
    if (formList.length === 0) {
      navigate(-1);
    }
  }, [formList, navigate]);

  return {
    clearFormError,
    errorsAfterSubmit,
    isLoading,
    formState,
    hasErrors,
    showError,
    formList,
    removeForm,
    setFormContext,
    handleSubmit,
    updateFormState,
  };
};

export default useFormList;
