import useCrudService from 'shared/crud';
import { useFinanceApi } from 'api';
import { cloneDeep } from 'lodash';
import { useDateService } from 'shared/uibuilder/dateService';
import serviceBudgetValidation from './Create/serviceBudgetValidation';

export const BUDGET_VERSIONS_PATH = '/budgeting/versions';
export const BUDGET_VERSIONS_API_PATH = '/budgets/versions';

export const BUDGET_OBJECTIVE_UNITS = {
  UNITS: 'UNITS',
  PERCENTAGE: 'PERCENTAGE',
  MONETARY: 'MONETARY',
};

export const BUDGET_OBJECTIVE_UNIT_OPTIONS = {
  [BUDGET_OBJECTIVE_UNITS.UNITS]: 'units',
  [BUDGET_OBJECTIVE_UNITS.MONETARY]: '$',
  [BUDGET_OBJECTIVE_UNITS.PERCENTAGE]: '%',
};

export const BUDGET_VERSION_STATUSES = {
  REJECTED: 'REJECTED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
};

export const BUDGET_VERSION_STATUS_OPTIONS = {
  [BUDGET_VERSION_STATUSES.REJECTED]: 'Rejected',
  [BUDGET_VERSION_STATUSES.AWAITING_APPROVAL]: 'Awaiting Approval',
  [BUDGET_VERSION_STATUSES.APPROVED]: 'Approved',
};

const useBudgetVersionService = () => {
  const singleResourceUrl = '/budgets/versions/:id';
  const { getCurrentDateTime, formatDateForAPI } = useDateService();
  const now = () => formatDateForAPI(getCurrentDateTime());

  const {
    search: baseSearch,
    getById,
    create: baseCreate,
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: BUDGET_VERSIONS_API_PATH,
    apiService: useFinanceApi,
  });

  const searchByBudget = async (id: string, searchRequest: any) => {
    const searchParams = cloneDeep(searchRequest);

    searchParams.filter['budget.id'] = {
      eq: id,
    };

    return baseSearch(searchParams);
  };

  const getActive = async (id: string) => {
    const date = now();
    const result = await baseSearch({
      filter: {
        'budget.id': {
          eq: id,
        },
        startDate: {
          le: date,
        },
        endDate: {
          ge: date,
        },
      },
    });

    return result.result[0];
  };

  const create = async (budgetVersion: any) => {
    const { categories, objectives, ...budgetData } = budgetVersion;

    return baseCreate({
      ...budgetData,
      categories,
      objectives,
    });
  };

  const getValidationSchema = () => Promise.resolve(serviceBudgetValidation);

  return {
    getById,
    searchByBudget,
    getActive,
    create,
    getValidationSchema,
  };
};

export default useBudgetVersionService;
